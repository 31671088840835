body {
  margin: 0 !important;
  height: 100%;
}
/* .App {
  font-family: sans-serif;
  text-align: center;
  background-color: #f6f9fe;
  height: 100vh;
} */
.image-item {
  display: flex;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
button {
  height: 40px;
}
